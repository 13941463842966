<template>
  <main style="padding-top: 100px;">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1>Termeni si conditii</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="margin_30_20">
      <b-row>
        <b-col md="12">
          Termeni si conditii
        </b-col>
         <div class="main-wrapper">
              <div class="page messages">
                <div data-placeholder="messages"></div>
                <div data-bind="scope: 'messages'">
                  <!-- ko if: cookieMessages && cookieMessages.length > 0 --><!-- /ko --><!-- ko if: messages().messages && messages().messages.length > 0 --><!-- /ko -->
                </div>
              </div>
              <div class="columns">
                <div class="column main">
                  <input
                    name="form_key"
                    type="hidden"
                    value="igZl5wCcEPUSOKTy"
                  />
                  <div
                    id="authenticationPopup"
                    data-bind="scope:'authenticationPopup'"
                    style="display: none"
                  >
                 
                  </div>
             
                  <p style="text-align: justify">
                    <span lang="ro-MD" style="font-size: medium"
                      ><b>1.</b><strong>&nbsp;</strong></span
                    ><strong
                      ><span style="font-size: medium; text-align: left"
                        >Acceptul condi<span>ţ</span>iilor de
                        utilizare&nbsp;</span
                      ></strong
                    >
                  </p>
                  <p>
                    <br /><span style="font-size: medium">
                      Site-ul Coressy este denumirea comercială a S.C ILASC DELIVERY 
                      S.R.L numit pe parcursul acestor termeni și
                      condi<span>ţ</span>ii: Coressy. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Folosirea acestui website, a serviciilor conținute în el,
                      este gratuită sub rezervă respectării de către vizitatori
                      a acestor termeni și condiții. Că vizitator și deci
                      utilizator al website-ului Coressy sunteți de acord să
                      respectați aceste reguli și veți fi în exclusivitate
                      răspunzător pentru tot ceea ce întreprindeți în legătură
                      cu Coressy. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Ne rezervăm dreptul de a modifica în orice moment
                      structura, conținutul și serviciile oferite de site-ul Coressy 
                      și suspendarea acestuia cu sau fără o înștiințare
                      prealabilă. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Regulile de utilizare a website-ului coressy.com pot fi
                      modificate în orice moment, fără o anunțare prealabilă a
                      utilizatorilor, iar întrucat aceste reguli sunt oricând
                      accesibile pe site, dumneavoastră vă revine
                      responsabilitatea să le cunoașteți și să le respectați. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Aceste reguli statuează modul de folosire de către
                      utilizatori a website-ului Coressy, iar relațiile sau
                      eventualele probleme ce pot apărea sunt guvernate de legi
                      aplicabile în România. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      <strong>2. Dreptul de proprietate </strong></span
                    ><br />
                    <br /><span style="font-size: medium">
                      Livrarea bunurilor și serviciilor achiziționate de pe
                      acest site sunt destinate exclusiv pentru uz personal.
                      Fiecare client răspunde de bunurile achiziționate și
                      garantează că nu sunt pentru revânzare. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Anumite produse de pe site-ul Coressy sunt destinate
                      pentru anumite categorii de varstă. Trebuie să verificați
                      că produsul pe care-l comandați este potrivit
                      destinatarului. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Atunci când plasați o comandă pe acest site, vi se poate
                      cere să furnizați o adresă de email și parolă. Trebuie să
                      vă asigurați că vă păstrati în sigurantă combinația
                      acestor detalii și că păstrati confidențialitatea acestor
                      informații. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Compania ILASC Delivery va luca toate măsurile posibile pentru
                      a păstra detaliile comenzilor dumneavoastră și securitatea
                      plătii. Nu suntem răspunzători pentru orice pierdere pe
                      care o puteți suferi dacă un terț achizitionează accesul
                      neautorizat la datele pe care le furnizați în momentul
                      accesării sau comandării pe site. </span
                    ><br />
                    <br /><span style="font-size: medium">
                      Orice comandă pe care ne-o plasați este conditionată de
                      disponibilitatea produselor, de capacitatea livrării și de
                      acceptul nostru și al partenerului nostru. În cazul unei
                      comenzi online, vă vom trimite o confirmare a primirii
                      mesajului prin email. Această confirmare email va fi
                      produsă în mod automat, astfel veți obține confirmarea
                      detaliilor comenzilor dumneavoastră. În cazul în care un
                      detaliu este incorect, trebuie să ne informați imediat cu
                      privire la acesta. Faptul că ați primit o confirmare
                      automată nu inseamnă neapărat că fie noi sau partenerul
                      asociat vom fi capabili să onorăm comandă. Odată ce am
                      trimis emailul de confirmare, vom verifică
                      disponibilitatea și capacitatea de livrare.</span
                    >
                  </p>
                  <p style="text-align: justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"><b></b></span
                    ></span>
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><b>3. Costuri și plăți</b></span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        >&nbsp; &nbsp; &nbsp;Orice contract pentru oferta de
                        livrare de pe acest site este încheiat între
                        dumneavoastră și partenerul asociat, iar pentru
                        furnizarea de bunuri și servicii de pe acest site este
                        un contract între dumneavoastră și Coressy. Veți avea în
                        vedere faptul că trebuie să aveți grijă la furnizarea
                        detaliilor cu privire la comanda dumneavoastră, plus
                        garantarea că aceste detalii sunt corecte și complete la
                        momentul comenzii. De asemenea, trebuie să existe
                        garanția faptului că există fonduri suficiente pentru a
                        se face plata.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Toate prețurile
                        listate pe site sunt corecte la momentul publicării, cu
                        toate acestea ne rezervăm dreptul de a le modifica în
                        viitor. Prețurile includ taxele relevante de vânzare și
                        taxele de livrare. Ne rezervăm dreptul de a modifica
                        produsele și serviciile disponibile spre vânzare pe site
                        și de a opri listarea partenerilor, bunurilor și
                        serviciilor.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Toate prețurile
                        listate pe site pentru livrarea comenzii de către
                        partenerul asociat sau de către un partener de livrare
                        reflectă prețul restaurantului asociat și taxele terței
                        părți partener de livrare. Ne propunem ca aceste date să
                        le actualizăm. În cazul în care prețul listat nu este
                        unul actual și partenerul ne aduce la cunoștință imediat
                        după plasarea comenzii, vă vom contacta pentru a vă
                        informa&nbsp; cu privire la diferența de preț și aveți
                        opțiunea de a renunța la comandă.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Toate prețurile
                        listate pe site pentru bunuri și servicii de către coressy 
                        reflectă prețul la momentul includerii în listă. Ne
                        vom propune ca aceste prețuri să le actualizăm în cazul
                        modificărilor. Dacă prețul listat nu este curent, vă vom
                        contacta pentru a vă informa cu privire la diferență și
                        puteți avea opțiunea de a renunța la comandă.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;T</span>oate prețurile sunt
                        listate pe site și sunt corecte la momentul publicării,
                        cu toate acestea ne rezervăm dreptul de a le modifica în
                        viitor.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Prețul total al
                        comenzii de bunuri și servicii, inclusiv taxele de
                        livrare și alte cheltuieli vă vor fi afișate pe site în
                        momentul plasării comenzii. Plata integrală trebuie să
                        se facă pentru toate bunurile expediate și pentru toate
                        serviciile furnizate. Plata poate fi făcută în numerar
                        sau dacă este posibil pe site, prin plată online.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Dacă alegeți plata
                        online, plata trebuie făcută înaintea livrării comenzii.
                        Pentru a se asigura un sistem securizat de cumpăraturi
                        online, detaliile cardului dumneavoastră de debit/credit
                        vor fi criptate pentru a preveni posibilitatea de a fi
                        citite de către cineva. De asemenea, banca dumneavoastră
                        emitentă a cardului a cărui posesor sunteți poate
                        efectua controale de securitate și vă poate confirma
                        plasarea comenzii.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Procesarea tuturor
                        plăților electronice efectuate de către client este
                        realizată de Procesatorul Serviciilor de Plată, o
                        instituție autorizată de plată care poate oferi servicii
                        de plată în conformitate cu prevederile Directivei
                        2007/64/CE privind serviciile de plată pe piața internă
                        și Ordonanța de Urgență a Guvernului nr. 113/2009
                        privind serviciile de plată.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Coressy nu prestează
                        niciun serviciu aflat sub incidența Ordonanței de
                        Urgență a Guvernului nr.113/2009 privind serviciile de
                        plată, orice astfel de servicii fiind subcontractate
                        către și efectuate de instituții autorizate de
                        plată.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"><b>3.1 Platile in sistemul francizat se proceseaza catre fiecare francizat per localitate</b></span></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>1. SC EralexGlo Delivery SRL avand codul de identificare fiscala RO42860540 si numar de inmatriculare J8/1433/2021
, cu sediul in: Str. Dimitrie Anghel 17, ap 73 , Jud. Brașov, telefon: 0773 796 693, email: brasov@coressy.com</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"><b>4. Livrarea</b></span></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Durata livrării
                        comenzii este aproximativă și poate varia. Comanda va fi
                        livrată la adresa indicată de către dumneavoastră la
                        momentul plasării comenzii.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Dacă livrarea comenzii
                        se realizează prin restaurantul asociat sau prin
                        partenerii săi de livrare, partnerul asociat are
                        responsabilitatea de a asigura livrarea comenzii în timp
                        util.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul în care
                        livrarea se realizează prin intermediul Coressy sau o
                        parte terță a livrării care&nbsp; este atribuită de
                        către Coressy, vom avea grijă să vă furnizăm în timp
                        util. Nu ne asumăm răspunderea pentru orice întârziere a
                        livrării în niciun altfel de caz.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Toate comenzile vor fi
                        livrate de către un curier în măsură. Noi și partenerii
                        asociați vom depune tot efortul pentru a livra în
                        termenul precizat, cu toate acestea, nu vom fi
                        răspunzători pentru orice prejudiciu adus de întârzierea
                        livrării comenzii. Dacă comanda nu este livrată în
                        timpul estimat, vă rugăm contactați prima dată
                        restaurantul în speță. Ne puteți contacta și pe noi prin
                        telefon sau email și noi vom depune toate eforturile în
                        încercarea să primiți comanda cât mai repede
                        posibil.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul întârzierii
                        livrării, taxa de livrare nu va fi anulată și nu va fi
                        suportată de către Coressy.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Toate riscurile legate
                        de livrarea comenzii vor cădea în sarcina dumneavoastră
                        odată cu livrarea.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul neacceptării
                        livrării comenzii la timpul pentru care se preconizează
                        sau în imposibilitatea noastră de a livra la timpul
                        cuvenit din cauza incapacității de a oferi instrucțiuni
                        adecvate sau autorizări, atunci aceste comenzi vor fi
                        considerate a fi livrate către dumneavoastră și
                        implicit, toate riscurile și responsabilitatea în
                        legătură cu astfel de comenzi vor fi plasate asupra
                        dumneavoastră. Orice depozitare, asigurare sau alte
                        costuri pe care le suportăm ca urmare a incapacității de
                        livrare va fi responsabilitatea dumneavoastră și va
                        trebui să ne despăgubiți integral pentru astfel de
                        costuri.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Trebuie să vă
                        asigurați că la momentul livrării bunurilor, inclusiv
                        accesul în cazul în care este necesar, a fost pusă în
                        aplicare livrarea în condiții de siguranță ale acestor
                        mărfuri. Nu putem fi responsabili pentru nicio pagubă,
                        cost sau cheltuială asociată acestor bunuri sau ipotezei
                        în care acestea apar ca rezultat al incapacității de a
                        asigura accesul adecvat sau condițiile livrării.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Partenerii asociați se
                        ocupă de pregătirea comenzii, de livrarea comenzii
                        dumneavoastre la locul solicitat, de livrarea acesteia
                        în timpul confirmat și de informarea dacă există
                        probabilitatea nerespectării timpului de livrare
                        estimat.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Compania și
                        restaurantele asociate nu vor fi răspunzătoare față de
                        dumneavoastră pentru orice pierderi, datorii, costuri,
                        daune sau cheltuieli care decurg din livrarea cu
                        întârziere.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Vă rugăm să aveți în
                        vedere probabilitatea partenerilor asociați de a nu
                        putea onora comenzile în anumite locații. În astfel de
                        situațiii, noi sau partenerii asociați, vă vom informa
                        folosind datele de contact pe care ni le furnizați
                        atunci când se face comanda și se procedează la anularea
                        respectivei comenzi sau se asigură livrarea la o adresă
                        alternativă.</span
                      ></span
                    >
                  </p>
                  <p id="anulare-comanda" style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"><b>5. Anulări comenzi</b></span></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul anulării unei
                        comenzi, trebuie să notificați imediat prin telefon
                        transmițând numărul comenzii. Dacă partenerul asociat
                        acceptă anularea, nu se aplică nicio taxa de
                        anulare.&nbsp; Dacă partenerul refuză anularea comenzii,
                        din cauza faptului că livrarea a fost complet pregătită
                        și/ sau personalul de livrare a fost deja
                        expediat,&nbsp; comanda nu poate fi anulată. Comenzile
                        deja expediate nu vor fi rambursate.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Putem anula un
                        contract dacă produsul nu este disponibil pentru orice
                        motiv. Vă vom anunța dacă este cazul și vă vom returna
                        orice plată pe care ați făcut-o.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul în care
                        anularea a fost făcută în timp și în același timp
                        restaurantul a acceptat anularea comenzii, vă vom
                        returna sau recredita debitul dumneavoastra cu suma
                        totală în termen de 14 zile, care include taxa de
                        livrare inițială ( dacă este cazul ) pe care ați
                        plătit-o pentru livrarea de bunuri și servicii.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"> </span></span
                    ><span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul neplăcut în
                        care partenerul asociat oferă un produs greșit, aveți
                        dreptul de a respinge livrarea acelui produs și vă va fi
                        restituită integral suma de bani pentru produsul lipsă.
                        Dacă partenerul asociat poate livra doar o parte a
                        comenzii ( câteva produse pot fi indisponibile ),
                        personalul ar trebui să vă informeze sau să vă propună
                        un înlocuitor pentru produsul lipsă. Aveți dreptul de a
                        refuza o livrare parțială și să obțineți rambursarea de
                        bani a comenzii. Nu suntem responsabili pentru livrarea
                        greșită sau parțială.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"><b>6. Informații</b></span></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>În cazul în care vom
                        solicita informații de la dumneavoastră pentru a asigura
                        livrarea bunurilor și serviciilor sunteți de acord să ne
                        furnizați informații corecte și complete.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Dumneavoastră ne
                        autorizați să folosim, să stocăm sau să procesăm
                        informații personale, în scopul de a oferi livrarea
                        bunurilor și serviciilor și pentru serviciile de
                        marketing și în scopuri de control. Astfel se pot
                        dezvălui anumite informații personale unor terțe părți
                        selectate în cazul în care considerăm că serviciile
                        acestor terțe părți pot fi de interes pentru
                        dumneavoastă sau în cazul în care acest lucru este cerut
                        de lege sau în scopul asigurării livrării de bunuri și
                        servicii. Mai multe informații pot fi găsite in Politica
                        noastră de confidențialitate.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Aveți dreptul de a
                        solicita o copie a informațiilor personale pe care le
                        deținem de la dumneavoastră. Vă rugăm să ne contactați
                        dacă doriți să solicitați aceste informații.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <a name="_GoBack"></a>
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><b>7. Site-uri afiliate</b></span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>S-ar putea să existe
                        link-uri unor site-uri terțe pe site-ul Coressy. Noi nu
                        reprezentăm calitatea produselor și serviciilor
                        furnizate de către astfel de terți și nici nu avem vreun
                        control asupra conținutului sau disponibilității acestor
                        site-uri și nu ne putem accepta vreo responsabilitate
                        pentru conținutul site-urilor părților terțe sau a
                        serviciilor sau bunurilor pe care acestea vi le
                        oferă.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"><b>8. Reclamații</b></span></span
                    >
                  </p>
                  <p lang="ro-MD" style="text-align: justify">
                    <span style="color: #000000"> </span
                    ><span style="font-family: 'Times New Roman', serif"
                      ><span style="font-size: medium"
                        ><span style="color: #000000"
                          ><span style="font-family: Calibri, serif"
                            ><span>&nbsp; &nbsp; &nbsp;</span>Pentru orice
                            nerespectare a acestui set de reguli, observație,
                            plângere sau problemă legată de Coressy vă rugăm să
                            ne scrieți la adresa info@coressy.com</span
                          ></span
                        ></span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><b>9. Limitarea datoriilor</b></span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Responsabilitatea a
                        fost luată pentru a se asigura că informațiile
                        disponibile pe website sunt corecte și fără erori. Ne
                        cerem scuze pentru eventualele erori sau omisiuni care
                        ar putea apărea. Nu putem garanta că utilizarea
                        site-ului va fi fără erori sau potrivit scopului în timp
                        util, defectele vor fi corectate sau serverele sunt
                        lipsite de viruși sau de asemenea nu putem garanta
                        întreaga funcționalitate, acuratețea și fiabilitatea
                        site-ului și nu oferim nicio garanție, expresă sau
                        implicită în legatură cu îndeplinirea obiectivului sau
                        corectitudinii.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Prin acceptarea
                        acestor termeni de utilizare, dumneavoastră sunteți de
                        acord să ne scutiți de orice răspundere care rezultă din
                        utilizarea informațiillor de la terțe părți sau din
                        utilizarea oricăreri terțe părți a website-ului sau din
                        consumul alimentelor sau băuturilor ale unui restaurant
                        asociat.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Nu suntem răspunzători
                        de orice datorie față de dumneavoastră pentru furnizarea
                        de produse și servicii decât în măsura permisă de
                        legislația aplicată.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Nu ne asumăm
                        răspunderea pentru eventualele întârzieri, erori,
                        omisiuni sau pierderi de informații transmise, viruși
                        sau alte programe distructive transmise calculatorului
                        personal prin intermediul site-ului nostru.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Nu vom fi trași la
                        răspundere pentru orice eșec sau întârziere în
                        executarea serviciilor sau furnizarea produselor în
                        cazul în care apar probleme ca urmare a oricărui act sau
                        omisiune, care este în afara controlului nostru, precum
                        evenimente inevitabile sau acțiuni ale părților
                        terțe.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Dacă ne-am angajat în
                        a furniza comenzi identice sau similare la mai mulți
                        clienți și suntem împiedicați de la îndeplinirea în
                        totalitate a obligațiilor noastre ca urmare a unui caz
                        de forță majoră, ne putem decide care dintre comenzi le
                        vom onora și în ce măsură.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Produsele
                        comercializate de noi sunt prevăzute doar pentru uzul
                        intern privat și al consumatorului. Prin urmare, nu ne
                        asumăm responsabilitatea pentru orice pierdere
                        indirectă, pierderi de date, pierderi de venituri sau
                        profit, daune materiale sau pierderea unor terțe părți
                        care decurge din utilizarea site-ului sau din
                        achiziționarea oricărui produs sau serviciu oferit de
                        noi.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Ne-am luat toate
                        măsurile rezonabile pentru a preveni frauda pe internet
                        și pentru a asigura că toate datele colectate de la
                        dumneavoastră sunt stocate în siguranță pe cât posibil.
                        Cu toate acestea, nu putem fi trași la răspundere în
                        cazul extrem de abuz al serverelor noastre securizate
                        sau cele ale terților.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Toate prețurile sunt
                        în&nbsp;RON. TVA-ul este inclus în cazul în care este
                        indicat.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Noi putem subcontracta
                        orice parte sau părți din serviciile și bunurile pe care
                        le oferim și ne atribuim orice parte sau părți din
                        drepturile noastre în temeiul acestor termeni&nbsp; și
                        condiții fără consimțământul dumneavoastră și fără vreo
                        obligație de a vă notifica.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Noi putem modifica
                        Termenii și Condițiile în orice moment fără o eventuală
                        notificare.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Plata trebuie să fie
                        făcută la momentul comenzii cu cardul de credit sau la
                        momentul livrării, prin numerar. Imposibilitatea de a
                        plăti la timp va conduce la anularea comenzii.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>A nu se folosi niciun
                        sistem automat sau program în legătură cu site-ul
                        nostru.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>A nu se prelua orice
                        informație de identificare personală de pe site, a se
                        utiliza sistemele de comunicare oferite de site-ul web
                        în scopul solicitărilor comerciale,&nbsp; a se solicita
                        pentru orice motiv, orice utilizator al site-ului&nbsp;
                        sau a se publica/distribui orice voucher sau cod in
                        legătură cu website-ul.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Termenii și condițiile
                        împreună cu politica de confidențialitate, orice
                        formular de plată și instrucțiunile de plata constituie
                        întregul acord între părți.&nbsp; Niciun alt termen
                        exprimat sau implicit nu ar trebui să facă parte din
                        acest acord. În cazul unui conflict între termenii și
                        condițiile și orice alt termen sau altă prevedere de pe
                        site, acești termeni și condiții vor prevala.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Dacă orice termen sau
                        condiție ale acordului nostru vor fi considerate
                        invalide, ilegale sau inaplicabile, părțile convin ca
                        respectivele termene sau condiții să fie eliminate, iar
                        acordul va rămâne în vigoare fără acestea.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Acești termeni și
                        condiții și acordul nostru vor fi guvernate și
                        interpretate în conformitate cu legile din România.
                        Părțile la prezentul regulament se supun jurisdicției
                        exclusive ale instanțelor din România.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Nicio întârziere sau
                        eșec din partea noastră pentru a asigura respectarea
                        drepturilor sau a remedierilor în temeiul acordului va
                        constitui o renunțare din partea noastră a unor astfel
                        de drepturi sau remedieri, exceptând faptul în care
                        derogarea este confirmată în scris.</span
                      ></span
                    >
                  </p>
                  <p style="text-align: justify" align="justify">
                    <span style="font-size: medium"
                      ><span lang="ro-MD"
                        ><span>&nbsp; &nbsp; &nbsp;</span>Acești termeni și
                        condiții și un contract (și toate obligațiile
                        noncontractuale care decurg&nbsp; din sau sunt în
                        legătură cu acestea) vor fi guvernate și interpretate în
                        conformitate cu legile din România. Ambele părți se
                        supun jurisdicției non-exclusiviste instanțelor din
                        România. Toate relațiile, corespondența și contactele
                        dintre părți trebuie efectuate în limba română.</span
                      ></span
                    >
                  </p>
                  <p
                    lang="ro-MD"
                    style="text-align: justify"
                    align="justify"
                  ></p>
                  <p lang="ro-MD" style="text-align: justify">
                    <span style="color: #000000"> </span
                    ><span style="font-family: 'Times New Roman', serif"
                      ><span style="font-size: medium"
                        ><span style="color: #000000"
                          ><span style="font-family: Calibri, serif"
                            ><span>&nbsp; &nbsp; &nbsp;</span>PENTRU UTILIZAREA
                            ACESTUI SITE ESTE NECESARĂ CITIREA ȘI ACCEPTAREA
                            ACESTOR REGULI ÎN TOTALITATEA LOR.<br />
                            VIZITAREA ÎN CONTINUARE A ACESTUI SITE PRESUPUNE CĂ
                            ÎNȚELEGEȚI, SUNTEȚI DE ACORD ȘI AȚI ACCEPTAT
                            TERMENII ȘI CONDIȚIILE MENȚIONATE ÎN ACEASTĂ
                            PAGINĂ.</span
                          ></span
                        ></span
                      ></span
                    >
                  </p>
                </div>
              </div>
            </div>
      </b-row>
    </b-container>
  </main>
</template>

<script>

export default {
  name: 'Terms',
  data() {
    return {
      isBusy: false
    }
  }
}
</script>

